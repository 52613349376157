import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { ColophonComponent } from './colophon/colophon.component';
import { KoCategoryComponent } from './categories/ko-category/ko-category.component';
import { VoCategoryComponent } from './categories/vo-category/vo-category.component';
import { KoLocationComponent } from './location/ko-location/ko-location.component';
import { PoCategoryComponent } from './categories/po-category/po-category.component';
import { MboCategoryComponent } from './categories/mbo-category/mbo-category.component';
import { HboWoCategoryComponent } from './categories/hbo-wo-category/hbo-wo-category.component';
import { PoLocationComponent } from './location/po-location/po-location.component';
import { VoLocationComponent } from './location/vo-location/vo-location.component';
import { MboLocationComponent } from './location/mbo-location/mbo-location.component';
import { HboWoLocationComponent } from './location/hbo-wo-location/hbo-wo-location.component';
import { ArticleComponent } from './articles/article/article.component';
import { MediaLeftTextRightComponent } from './articles/blocks/media-left-text-right/media-left-text-right.component';
import { MediaRightTextLeftComponent } from './articles/blocks/media-right-text-left/media-right-text-left.component';
import { TextRightComponent } from './articles/blocks/text-right/text-right.component';
import { TextLeftComponent } from './articles/blocks/text-left/text-left.component';
import { BannerComponent } from './articles/blocks/banner/banner.component';
import { SubCategoryListComponent } from './articles/sub-category-list/sub-category-list.component';
import { ArticleListComponent } from './articles/article-list/article-list.component';
import { PoYearCapacityTableComponent } from './location/po-location/po-year-capacity-table/po-year-capacity-table.component';
import { SoCategoryComponent } from './categories/so-category/so-category.component';
import { IntCategoryComponent } from './categories/int-category/int-category.component';
import {MapComponent} from './map/map.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OpenDaysComponent} from './open-days/open-days.component';
import {CommonModule, DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { TextOnlyComponent } from './articles/blocks/text-only/text-only.component';
import {NouisliderModule} from 'ng2-nouislider';
import {FavouriteSchoolsComponent} from './favourite-schools/favourite-schools.component';
import {FavouriteSchoolButtonComponent} from './shared/components/favourite-school-button/favourite-school-button.component';
import {FavouriteSchoolsListComponent} from './favourite-schools/favourite-schools-list/favourite-schools-list.component';
import {FavouriteSchoolListDirective} from './favourite-schools/favourite-school-list.directive';
import {FavouriteSchoolsDownloadComponent} from './favourite-schools/favourite-schools-download/favourite-schools-download.component';
import {ApplicationContainerComponent} from './application-container/application-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LaunchComponent } from './launch/launch.component';
import { PossiblyExternalDirective } from './possibly-external.directive';
import { LinkToLocationComponent } from './link-to-location/link-to-location.component';
import { NewcomersComponent } from './newcomers/newcomers.component';
import { SchoolChoiceComponent } from './school-choice/school-choice.component';
import { RefugeeQuestionnaireComponent } from './refugee-questionnaire/refugee-questionnaire.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { SubmittedQuestionnaireComponent } from './refugee-questionnaire/submitted-questionnaire/submitted-questionnaire.component';
import { VoCapacityComponent } from './vo-capacity/vo-capacity.component';
import {
    RECAPTCHA_SETTINGS,
    RecaptchaFormsModule,
    RecaptchaModule,
    RecaptchaSettings
} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import { VavoLocationComponent } from './location/vavo-location/vavo-location.component';
import * as Sentry from '@sentry/angular';
import {MultiSelectModule} from 'primeng/multiselect';
import {HighlightDirective} from './shared/directives/highlight.directive';
import {PaginatorModule} from 'primeng/paginator';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import player from 'lottie-web';
import {LottieModule} from "ngx-lottie";

registerLocaleData(localeNl);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=4');
}

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        HomeComponent,
        ColophonComponent,
        KoCategoryComponent,
        VoCategoryComponent,
        KoLocationComponent,
        PoCategoryComponent,
        MboCategoryComponent,
        HboWoCategoryComponent,
        PoLocationComponent,
        VoLocationComponent,
        MboLocationComponent,
        HboWoLocationComponent,
        ArticleComponent,
        MediaLeftTextRightComponent,
        MediaRightTextLeftComponent,
        TextRightComponent,
        TextLeftComponent,
        BannerComponent,
        SubCategoryListComponent,
        ArticleListComponent,
        PoYearCapacityTableComponent,
        SoCategoryComponent,
        IntCategoryComponent,
        MapComponent,
        OpenDaysComponent,
        TextOnlyComponent,
        FavouriteSchoolsComponent,
        FavouriteSchoolButtonComponent,
        FavouriteSchoolsListComponent,
        FavouriteSchoolListDirective,
        FavouriteSchoolsDownloadComponent,
        ApplicationContainerComponent,
        NotFoundComponent,
        LaunchComponent,
        PossiblyExternalDirective,
        LinkToLocationComponent,
        NewcomersComponent,
        SchoolChoiceComponent,
        RefugeeQuestionnaireComponent,
        SubmittedQuestionnaireComponent,
        VoCapacityComponent,
        VavoLocationComponent,
        HighlightDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        NouisliderModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'nl',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MultiSelectModule,
        PaginatorModule,
        NoopAnimationsModule,
        ClipboardModule,
        OverlayModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        MatSnackBar,
        Overlay,
        { provide: LOCALE_ID, useValue: 'nl'},
        { provide: RECAPTCHA_SETTINGS, useValue: {siteKey: environment.captcha, size: 'normal', badge: 'inline'} as RecaptchaSettings },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
